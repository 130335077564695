<template>
  <div>

    <HeaderMain />

    <!-- КАК ВЕРНУТЬ ЗАЁМ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: var(--accent-2);">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100 z-2">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">
                    Как вернуть заём<br>банковской картой,<br>в терминале «Элекснет»<br>или в кассе банка
                  </h1>
                  <h1 class="text-mini white" style="color: #fff; text-align: right;">Офлайн оплата наличными<br>от 1-5 дней</h1>
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/return/terminal.png" alt="terminal"
                   style="position: absolute;
                 bottom: 0;
                 height: 80%;
                 right: 20%;"
              >

              <div class="w-layout-vflex flex-good z-2"
                   style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
              >
                <div>
                  <div class="w-layout-vflex flex-good"
                       style="flex-flow: row;"
                  >
                    <div>
                      <img height="40px" alt="visa" src="../../../../../public/images/return/1.svg">
                    </div>
                    <div>
                      <img height="40px" alt="mir" src="../../../../../public/images/return/2.svg">
                    </div>
                    <div>
                      <img height="40px" alt="mastercard" src="../../../../../public/images/return/3.svg">
                    </div>
                  </div>
                </div>
                <div style="width: 40%; max-width: 300px;">
                  <div class="w-layout-vflex flex-good justify-content-end"
                       style="flex-flow: row;"
                  >
                    <h1 class="text-mini white" style="color: #fff; margin-top: auto; text-align: right;">Получение на карту<br>1-5 минут</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- КНОПКИ ПЕРЕКЛЮЧЕНИЯ ТИПА ОПЛАТЫ -->
    <div class="section color">
      <div
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
          class="container one w-container pt-0 flex flex-direction-row direction-adaptive"
          style="border-bottom: none;">
        <div class="w-100">
          <h2 class="text-mini white bold mt-0">Выберите средства оплаты<br>и следуйте инструкции</h2>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                @click="changeType(0)"
                :class="[type == 0 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Картой онлайн
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                @click="changeType(1)"
                :class="[type == 1 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Терминал «Элекснет»
            </a>
          </div>
        </div>

        <div class="w-100">
          <div class="w-layout-hflex flex-item-best pa-0">
            <a
                @click="changeType(2)"
                :class="[type == 2 ? 'button-black-invert' : 'button-black']"
                class="button-bust w-button margin-adaptive">
              Касса банка
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- ОПЛАТА ЗАЙМА С ПОМОЩЬЮ... -->
    <div class="section color">
      <!-- ТИП 0 -->
      <div v-if="type == 0" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
           class="container one w-container pt-0 pb-0"
           style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: var(--green-50);
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Картой онлайн</div>
              <div class="w-100">
                <h1 class="heading">Оплата займа<br>с помощью<br>банковской<br>карты</h1>
              </div>
            </div>

            <div class="w-layout-vflex flex-good "
                 style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
            >
              <div class="w-layout-hflex flex-item-best pa-0">
                <a @click="openLoan" class="button-bust w-button">Погасить заём</a>
              </div>

              <h2 class="text-mini white bold text-align-right">Онлайн оплата<br>1-5 минут</h2>
            </div>
          </div>
          <!-- инструкция для одобрения займа -->
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right"
               style="padding: 25px 25px 35px;">
            <div class="text-mini semibold">Инструкция</div>
            <h2 class="heading h2">Для погашения займа банковской картой необходимо сделать следующие шаги:</h2>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Зайдите в личный кабинет.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Нажмите «Оплатить».</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Укажите банковскую карту, с которой будет производиться оплата.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Нажмите на кнопку «Оплатить».</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Заём будет оплачен в течение 5 минут.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4 accent-color">Сразу после зачисления средств вам будет доступен новый заём. Как правило, Броктон увеличивает размер лимита для добросовестных клиентов!</h2>
              </div>
            </div>

          </div>
        </div>
        <hr class="bold mx-25" style="margin-top: 30px; margin-bottom: 30px;">
      </div>

      <!-- ТИП 1 -->
      <div v-if="type == 1" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
           class="container one w-container pt-0 pb-0"
           style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #ffe6cc;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Наличными оффлайн</div>
              <div class="w-100">
                <h1 class="heading">Оплата займа<br>с помощью<br>терминала<br>«Элекснет»</h1>
              </div>
            </div>

            <div class="w-layout-vflex flex-good "
                 style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
            >
              <div class="w-layout-hflex flex-item-best pa-0">
                <a @click="openLoan" class="button-bust w-button yellow-button">Найти на карте</a>
              </div>

              <h2 class="text-mini white bold text-align-right">Оплата «Элекснет»<br>от 1 дня</h2>
            </div>
          </div>
          <!-- инструкция для одобрения займа -->
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right"
               style="padding: 25px 25px 35px;">
            <div class="text-mini semibold">Инструкция</div>
            <h2 class="heading h2">Как погасить займ через терминал «Элекснет»:</h2>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Зайдите в личный кабинет и уточните сумму к возврату.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Найдите ближайший к вам терминал «Элекснет» в вашем городе.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">В меню терминала введите «Броктон» в строку поиска.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Введите номер телефона, который использовался при регистрации на Броктон, и сумму к оплате.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Внесите наличные в терминал и нажмите оплатить.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/6.svg" alt="number6">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Денежные средства поступают в счёт оплаты займа в течение 1 дня. Сохраняйте чек до полного закрытия займа.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4 accent-color">Сразу после зачисления средств вам будет доступен новый заём. Как правило, Броктон увеличивает размер лимита для добросовестных клиентов!</h2>
              </div>
            </div>

          </div>
        </div>
        <hr class="bold mx-25" style="margin-top: 30px; margin-bottom: 30px;">
      </div>

      <!-- ТИП 2 -->
      <div v-if="type == 2" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81"
           class="container one w-container pt-0 pb-0"
           style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               background-color: #CCDEFE;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Наличными оффлайн</div>
              <div class="w-100">
                <h1 class="heading">Оплата займа<br>с помощью<br>кассы банка</h1>
              </div>
            </div>

            <div class="w-layout-vflex flex-good "
                 style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
            >
              <div class="w-layout-hflex flex-item-best pa-0">
                <a @click="openLoan" class="button-bust w-button blue-button">Найти на карте</a>
              </div>

              <h2 class="text-mini white bold text-align-right">Оплата кассой банка<br>до 5 дней</h2>
            </div>
          </div>
          <!-- инструкция для одобрения займа -->
          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right"
               style="padding: 25px 25px 35px;">
            <div class="text-mini semibold">Инструкция</div>
            <h2 class="heading h2">Погасить заём через расчетный счет в кассе банка:</h2>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/1.svg" alt="number1">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Зайдите в личный кабинет.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/2.svg" alt="number2">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Нажмите «Оплатить».</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/3.svg" alt="number3">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Выберите способ оплаты «Банковский счет».</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/4.svg" alt="number4">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Вы увидите реквизиты для оплаты и сумму к оплате.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/5.svg" alt="number5">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Нажмите на кнопку «Распечатать» и получите платежное поручение.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/6.svg" alt="number6">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Посетите офис любого банка и отдайте платежное поручение кассиру-операционисту вместе с денежными средствами для оплаты займа.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/numbers/7.svg" alt="number7">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4">Оплата по займу поступит в течение 5 дней, и ваш заём будет оплачен.</h2>
              </div>
            </div>

            <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
              <div class="text-classic">
                <img class="img-40" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
              </div>
              <div class="w-layout-vflex item-col center gap-2">
                <h2 class="heading h4 accent-color">Сразу после зачисления средств вам будет доступен новый заём. Как правило, Броктон увеличивает размер лимита для добросовестных клиентов!</h2>
              </div>
            </div>

          </div>
        </div>
        <hr class="bold mx-25" style="margin-top: 30px; margin-bottom: 30px;">
      </div>
    </div>

    <!-- ПРЕИМУЩЕСТВА -->
    <div class="section color">
      <!-- ТИП 0 -->
      <div v-if="type == 0" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">

          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Преимущества</div>
              <h2 class="heading h2">Плюсы погашения<br>займа через банковскую карту:</h2>

              <div>
                <p class="text-mini white">Может взиматься комиссия от банка эмитента и/или процессингового центра.</p>
                <p class="text-mini white">Это самый быстрый способ перевода средств, как правило, оплата поступает в течение нескольких минут.</p>
                <p class="text-mini white">Оплата займа происходит полностью онлайн.</p>
              </div>

            </div>
          </div>

          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right w-100"
               style="padding: 25px 25px 35px;">

            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Важная информация</div>
              <h2 class="heading h2">Обратите внимание</h2>

              <div>
                <p class="text-mini white">Проверьте дату выпуска — карта не должна быть просрочена.</p>
              </div>

            </div>

            <div class="w-layout-hflex flex-item-best w-text" style="gap: 20px;">
              <a @click="goToHelp" class="button-link">Помощь</a>
            </div>
          </div>

        </div>

      </div>

      <!-- ТИП 1 -->
      <div v-if="type == 1" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">

          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Преимущества</div>
              <h2 class="heading h2">Плюсы возврата займа через<br>«Элекснет»:</h2>

              <div>
                <p class="text-mini white">Комиссия при оплате не взымается</p>
                <p class="text-mini white">Широкая сеть терминалов в 74 регионах России</p>
                <p class="text-mini white">Возможность оплаты онлайн через сайт</p>
                <p class="text-mini white">Поступление оплаты до 1 дня</p>
              </div>

            </div>
          </div>

          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right w-100"
               style="padding: 25px 25px 35px;">

            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Важная информация</div>
              <h2 class="heading h2">Обратите внимание</h2>

              <div>
                <p class="text-mini white">Обязательно проверяйте график работы терминала «Элекснет». Мы не отвечаем за их время работы.</p>
              </div>

            </div>

            <div class="w-layout-hflex flex-item-best w-text" style="gap: 20px;">
              <a @click="goToHelp" class="button-link">Помощь</a>
            </div>
          </div>

        </div>

      </div>

      <!-- ТИП 2 -->
      <div v-if="type == 2" data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0 pb-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum mt-0" style="grid-column-gap: unset;">

          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Преимущества</div>
              <h2 class="heading h2">Плюсы погашения займа через<br>банковский счёт:</h2>

              <div>
                <p class="text-mini white">Оплатить заём можно в любом банке, даже если вы не являетесь его клиентом.</p>
                <p class="text-mini white">Комиссия при оплате зависит от банка, который проводит платеж.</p>
              </div>

            </div>
          </div>

          <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
               class="grid-colum right w-100"
               style="padding: 25px 25px 35px;">

            <div class="w-layout-vflex item w-100">
              <div class="text-mini semibold">Важная информация</div>
              <h2 class="heading h2">Обратите внимание</h2>

              <div>
                <p class="text-mini white">Обязательно проверяйте график работы  вашего банка. <br>Мы не отвечаем за их время работы.</p>
                <p class="text-mini white">Денежный перевод может идти от 1 до 5 дней.</p>
              </div>

            </div>

            <div class="w-layout-hflex flex-item-best w-text" style="gap: 20px;">
              <a @click="goToHelp" class="button-link">Помощь</a>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- Броктон В ТВОЁМ ТЕЛЕФОНЕ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(to bottom right, #6F7B8F, #202329);">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Броктон в твоём<br>телефоне</h1>
                  <h1 class="text-mini white" style="color: #fff;">Установите наше приложение<br>и получите деньги за пару кликов</h1>
                </div>
              </div>

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       margin-bottom: 60px;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>

              <div class="w-layout-vflex flex-good "
                   style="
                       overflow: initial;
                       align-items: end;
                       flex-flow: row;
                       justify-content: space-between;"
              >
                <div class="install-image">
                  <img height="60px" alt="ruble" src="../../../../../public/images/ruble.svg">
                </div>
                <div>
                  <img
                      style="transform: translate(50px, 80px); margin-top: -100px;"
                      class="w-100 iphone"
                      alt="phone" src="../../../../../public/images/iphone.png">
                </div>
                <div style="width: 40%; max-width: 300px; text-align: right;" class="install-image">
                  <img style="max-width: 300px;" alt="install" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'HowToReturn',
  data() {
    return {
      type: 0,
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    openLoan() {
      this.$buefy.toast.open({
        message: 'У вас нет займа',
        type: 'is-warning'
      });
    },
    goToHelp() {
      this.$router.push('/support');
    },
    changeType(number) {
      this.type = number;
    },
    jqueryLoaded() {
    },
    reloadComponent() {
      this.$router.go(0);
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToReg() {
      this.$router.push('/registration-step1');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                  this.jqueryLoaded();
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
